import classNames from "classnames";
import Markdown from "markdown-to-jsx";

import { useEffect, useRef, useState } from "preact/compat";
import { isValidURL } from "~/utils";
import { useCustomizeStore } from "~/stores";
import LinkPreview from "./LinkPreview";

import "./index.scss";

interface IProps {
  isBot: boolean;
  text: string;
  done?: boolean;
}

export default function TextMessage({ text, isBot, done }: IProps) {
  const {
    customize: { brandColor },
  } = useCustomizeStore();
  const [previewURL, setPreviewURL] = useState("");
  const ref = useRef<HTMLDivElement>(null);

  const reactMarkdownClass = classNames("markdown-body", {
    "is-bot": isBot,
  });

  useEffect(() => {
    if (isBot && done && ref.current) {
      const hrefDOM = ref.current.querySelectorAll("a")[0];
      if (hrefDOM) {
        const href = hrefDOM.getAttribute("href");
        if (href && isValidURL(href)) {
          setPreviewURL(href);
        }
      }
    }
  }, [isBot, done, ref.current]);

  return (
    <>
      <div
        className="message-item-text"
        ref={ref}
        style={isBot ? {} : { backgroundColor: brandColor }}
      >
        <Markdown
          className={reactMarkdownClass}
          options={{ overrides: { a: { component: (props) => <a {...props} target="_blank" /> } } }}
        >
          {text || ""}
        </Markdown>
      </div>

      {previewURL && <LinkPreview url={previewURL} />}
    </>
  );
}
