import "./index.scss";

export default function StepLoading() {
  return (
    <div className="load-forty-three">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
