// Bot 的开关状态
export enum BotServiceStatus {
  PENDING = "pending",
  ACTIVE = "active",
  INACTIVE = "inactive",
}

// 存放类型及初始数据
export enum MessageType {
  TEXT = "text",
  VIDEO = "Video",
  DIVIDER = "DIVIDER",
  PRODUCT_SEARCH = "ProductSearch",
  PRODUCT_RECOMMENDER = "ProductRecommender",
  PRODUCT_COMPARE = "ProductCompare", // 大模型返回的商品对比
  KNOWLEDGE_SEARCH = "KnowledgeSearch",
  ONLY_CHAT = "OnlyChat",
  SEND_WAITING = "SENDWAITING",
  QUESTION_RECOMMENDR = "QuestionRecommender",
  LOADING_STEP = "LoadingStep",
  INSTRUCTION_COMPARE = "InstructionCompare",
  UNAVAILABLE = "Unavailable", // Bot 服务被关闭，不可用
  CONTACT = "Contact",
  CONTENT_FILTERED = "ContentFiltered",
  NO_CREDIT = "NoCredit",
  RATE_LIMIT_EXCEEDED = "RATE_LIMIT_EXCEEDED",
}

export enum RecommendQuestionType {
  TEXT = "text",
}

export type SegmentMessage = {
  sequenceNumber: number;
  text: string;
};

export enum MessageSubType {
  UPVOTE = "upvote",
  DOWNVOTE = "downvote",
  WELCOME = "welcome",
}

export enum FeedbackLog {
  NONE = "none",
  UPVOTED = "upvoted",
  DOWNVOTED = "downvoted",
  REGENERATED = "regenerated",
}

export type Message = {
  messageId?: string;
  type?: MessageType;
  subType?: MessageSubType;
  text?: string;
  isBot: boolean;
  createdAt?: Date;
  userName?: string;
  payload?: any; // 作为附加的可定制的数据
  done?: boolean;
  segments?: SegmentMessage[];
  metadata?: any;
  resource?: any;
  loadingSteps?: string[];
  compareData?: ProductCompareItem[];
  feedback?: FeedbackLog;
  feedbackLog?: {
    relatedMessageId: string;
    log: FeedbackLog;
  };
  mediaUrl?: string; // 用以存放 media 类型的 url
  isHistory?: boolean; // 是否是历史消息
};

export enum MessageAction {
  FULL_ANSWER = "answer",
  PARTIAL_ANSWER = "partial_answer",
}

type RawAnswer = {
  content: string;
};

type DataPayload = {
  answer: string | RawAnswer;
  instruction: string;
  runId?: string;
  run_id?: string;
  sequence_number: number;
};

type Resource = {
  ProductSearch: string;
  InstructionCompare: ProductCompareItem[];
};

type Metadata = {
  resource?: Resource;
  questionRec?: RecommendQuestion[];
};

export interface RecommendQuestion {
  type: string;
  content: string;
}

export interface MessageItem {
  type: MessageType;
  id: string; // message 的唯一 id 标识，等同于后端的 runId
  done?: boolean;
  text?: string;
  sequenceNumber?: number;
  loadingSteps?: number[];
  metadata?: Metadata;
}

export type MessageResponse = {
  type: MessageType;
  action: MessageAction;
  data: DataPayload;
  metadata?: Metadata;
};

export enum ResponseAction {
  ANSWER = "answer",
  PARTIAL_ANSWER = "partial_answer",
  ERROR = "error",
}

export enum FeedbackAction {
  LIKE = "like",
  DISLIKE = "dislike",
  COPY = "copy",
  REGENERATION = "regeneration",
}

// 当前的消息状态
export enum MessageState {
  INIT,
  AVAILABLE,
  SENDING,
  SENDWAITING,
  FAILED,
  RECEIVING,
  RECEIVED,
  STOPED,
}

export type ISettingMessage = {
  welcome: string;
  upvote: string;
  unanswerable: string;
  downvote: string;
  placeholder: string;
};

type ProductImages = { url: string }[];

// TODO:合并商品推荐和商品对比的类型
// 商品卡片类型
export type ProductSearchItem = {
  product_name: string;
  product_url: string;
  product_images: ProductImages;
  product_brand: string;
  // product_description: string;
  product_price: string;
};

// 商品对比类型
export type ProductCompareItem = {
  product_id: string;
  product_name: string;
  product_brand: string;
  product_url: string;
  product_images: ProductImages;
  product_price: string;
};

export type Pagination = {
  pageSize: number;
  totalCount: number;
  totalPages: number;
  currentPage: number;
};

export type PaginationRequestParams = {
  page?: number;
  pageSize?: number;
};

// 第三方平台对接
export enum Platform {
  SELF = "self", // TP 自己的平台
  SHOPIFY = "shopify",
  SHOPLINE = "shopline",
  BIGCOMMERCE = "bigcommerce",
  SHEIN = "shein",
  WALMART = "walmart",
  AMAZON = "amazon",
}

// 统计行为上报
export enum ReportBehavior {
  DISPLAY = 1,
  CLICK,
  PURCHASE,
  COMPARE,
}

export enum RENDER_MODE {
  FLOAT = "float", // 浮动小窗口
  FULL = "full", // 全屏模式
}

export enum HOST_MODE {
  MOBILE = "mobile",
  IFRAME = "iframe",
  DESKTOP = "desktop",
}

// 第三方平台对接
export enum PlatformBrand {
  SELF = "self", // 非平台，这里只是个默认值
  SHOPLINE = "shopline",
}

// 对外提供 Bot 的一些 Style 配置项
export interface BotStyleOptions {
  right?: string;
  bottom?: string;
}

// 所有的 Bot 配置项，统一放在这里
export interface BotConfig {
  code?: string;
  isDevMode?: boolean;
  renderMode?: RENDER_MODE;
  origin?: string;
  auto?: boolean;
  style?: BotStyleOptions;
  // 第三方对接平台，如 shopline, shopify 等
  platform?: PlatformBrand;
  platformConfig?: Record<string, any>;

  brandColor?: string;
}

// 集成方类型
export interface ShoplineObject {
  customer?: {
    id: string;
  };
}

export interface HistoryMessagesResponse {
  messages: object[];
  pagination: Pagination;
}
