import Icon from "@ant-design/icons";
import Markdown from "markdown-to-jsx";
import arrowSVG from "~/assets/icons/arrow_02.svg?react";
import { useBotStore, useCustomizeStore } from "~/stores";
import { ProductCompareItem } from "~/types";
import { useEffect, useRef, useState } from "preact/compat";
import { isMobileMode } from "~/utils/host";
import classNames from "classnames";

import "./index.scss";

interface IProps {
  compareData: ProductCompareItem[];
  text?: string;
}

export default function ProductComparision({ compareData = [], text }: IProps) {
  const {
    customize: { brandColor },
  } = useCustomizeStore();
  const { toggleCompareDialog, setCompareData } = useBotStore();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState(0);
  const listRef = useRef<HTMLDivElement>(null);
  const productItemWidth = 200;

  function handleViewDetails() {
    setCompareData(compareData);
    toggleCompareDialog();
  }

  // FIXME:bot宽度如果能动态变化时，对应的 page 也要动态变化
  useEffect(() => {
    // 根据父元素 product-list 的内宽度，以及 product-item 的宽度，计算出每页显示的个数
    const productListWidth = listRef.current?.clientWidth as number;
    // 计算出每页显示的个数
    const countPerPage = Math.ceil((productItemWidth * compareData.length) / productListWidth);
    // 计算出总页数
    const totalPage = Math.ceil(compareData.length / countPerPage);
    setTotalPage(totalPage);
    setSize(countPerPage);
  }, [listRef.current]);

  function handlePrevPage() {
    setPage((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
    // 滚动 productItemWidth 的宽度 * size
    listRef.current?.scrollBy({
      left: -productItemWidth * size,
      behavior: "smooth",
    });
  }

  function handleNextPage() {
    setPage((prev) => {
      if (prev < totalPage) {
        return prev + 1;
      } else {
        return prev;
      }
    });
    // 滚动 productItemWidth 的宽度 * size
    listRef.current?.scrollBy({
      left: productItemWidth * size,
      behavior: "smooth",
    });
  }

  return (
    <div className="product-comparision-message-box">
      {compareData.length > 0 && (
        <>
          <div className="product-list" ref={listRef}>
            {compareData.map((item, index) => {
              return (
                <div
                  className="product-item"
                  onClick={() => window.open(item.product_url)}
                  key={index}
                >
                  <img src={item.product_images[0].url} alt="" />
                  <div className="name">{item.product_name}</div>
                  <div className="price">${item.product_price}</div>
                </div>
              );
            })}
          </div>

          {/* 分页 */}
          <div className="pagination">
            <div className="prev action" onClick={handlePrevPage}>
              <Icon component={arrowSVG} />
            </div>
            <div className="page">{page}</div>
            <div className="page">/</div>
            <div className="page">{totalPage}</div>
            <div className="next action" onClick={handleNextPage}>
              <Icon component={arrowSVG} />
            </div>
          </div>

          <div className="compare-title">
            You choose {compareData.length} items above to compare
          </div>
        </>
      )}

      <Markdown className="markdown-box">{text || ""}</Markdown>

      <div
        className={classNames("view-detail", { mobile: isMobileMode })}
        style={{ backgroundColor: brandColor }}
        onClick={handleViewDetails}
      >
        View Details
      </div>
    </div>
  );
}
