import classNames from "classnames";
import PReact, { useRef } from "preact/compat";
import { Message, MessageType, ProductCompareItem } from "~/types";
import Feedback from "./Feedback";
import LoadingMessage from "./LoadingMessage";
import MessageItemErrorBoundary from "./MessageErrorBoundary";
import ProductCompareMessage from "./ProductCompareMessage";
import ProductSearchMessage from "./ProductSearchMessage";
import TextMessage from "./TextMessage";

import ProductComparision from "./ProductComparision";
import RecommendQuestions from "./RecommendQuestions";
import MessageCreatedTime from "./Time";

// import VideoMessage from "./VideoMessage";
import ContactCard from "./ContactCard";

import "./index.scss";
import "./markdown.scss";

interface MessageItemProps {
  message: Message;
  avatar: string;
  sendMessage: (text: string) => void;
  sendTextMessage: (text: string) => void;
}

const MessageItem: PReact.FC<MessageItemProps> = ({
  message,
  avatar,
  sendMessage,
  sendTextMessage,
}) => {
  const {
    type,
    text,
    isBot,
    subType,
    payload,
    resource,
    done,
    loadingSteps,
    createdAt,
    compareData,
  } = message;
  const renderItemDOMRef = useRef<HTMLDivElement>(null);

  const boxClassname = classNames("message-box", `${type}-type`, {
    bot: isBot,
    user: !isBot,
  });

  const blockClassname = classNames("block", `${type}-type`, {
    bot: isBot,
    user: !isBot,
  });

  const showAvatarBool = isBot && type !== MessageType.QUESTION_RECOMMENDR;
  const isTextRenderType = [
    MessageType.TEXT,
    MessageType.KNOWLEDGE_SEARCH,
    MessageType.UNAVAILABLE,
    MessageType.CONTENT_FILTERED,
    MessageType.NO_CREDIT,
    MessageType.RATE_LIMIT_EXCEEDED,
  ].includes(type as MessageType);

  // 针对空文本的消息，不进行消息渲染
  if (isTextRenderType && !text?.trim()) return null;

  // disable video type msg temporarily
  if (type === MessageType.VIDEO) return null;
  // if (type === MessageType.VIDEO && getVideoPopuped() === "true") return null; // original logic

  return (
    <div className={boxClassname}>
      <div className={blockClassname}>
        <div className="wrapper-item">
          {showAvatarBool && <img src={avatar} alt="" className="avatar" />}
          <MessageItemErrorBoundary>
            <div className={`message-item-render ${type}-type`} ref={renderItemDOMRef}>
              {/* 文本类型 */}
              {isTextRenderType && <TextMessage text={text as string} isBot={isBot} done={done} />}

              {(type === MessageType.SEND_WAITING || type === MessageType.LOADING_STEP) && (
                <LoadingMessage data={loadingSteps as string[]} />
              )}

              {type === MessageType.QUESTION_RECOMMENDR && (
                <RecommendQuestions
                  recommendQuestions={payload ?? []}
                  sendTextMessage={sendTextMessage}
                />
              )}

              {/* 商品搜索类型 */}
              {type === MessageType.PRODUCT_SEARCH && (
                <ProductSearchMessage
                  text={text as string}
                  data={resource?.ProductSearch}
                  isHistory={message.isHistory}
                />
              )}

              {/* 商品推荐类型 */}
              {type === MessageType.PRODUCT_RECOMMENDER && (
                <ProductSearchMessage
                  text={text as string}
                  data={resource?.ProductRecommender}
                  isHistory={message.isHistory}
                />
              )}

              {/* 商品对比类型 */}
              {type === MessageType.PRODUCT_COMPARE && (
                <ProductCompareMessage text={text as string} data={resource?.ProductCompare} />
              )}

              {/* 选中商品对比的卡片 */}
              {type === MessageType.INSTRUCTION_COMPARE && (
                <ProductComparision text={text} compareData={compareData as ProductCompareItem[]} />
              )}

              {/* Video 类型 (暂时去除intro video) */}
              {/* {type === MessageType.VIDEO && <VideoMessage message={message} />} */}

              {/* 联系方式 */}
              {type === MessageType.CONTACT && <ContactCard />}

              {/* 时间显示 */}
              <MessageCreatedTime
                isBot={isBot}
                time={createdAt as unknown as string}
                type={type}
                hoverRef={renderItemDOMRef}
              />

              <Feedback
                type={type}
                subType={subType}
                isBot={isBot}
                hoverRef={renderItemDOMRef}
                sendMessage={sendMessage}
                message={message}
              />
            </div>
          </MessageItemErrorBoundary>
        </div>
      </div>

      {/* 分割线 */}
      {/* {type === MessageType.DIVIDER && <DeviderMessage />} */}
    </div>
  );
};

export default MessageItem;
