import { useRequest } from "ahooks";
import { useState } from "preact/compat";
import { getHistoryMessages } from "~/api/chat";
import { useBotStore, useCustomizeStore, useMessageStore, useUserStore } from "~/stores";
import {
  BotServiceStatus,
  FeedbackLog,
  HistoryMessagesResponse,
  Message,
  MessageSubType,
  MessageType,
  Pagination,
  ProductCompareItem,
  RecommendQuestionType,
} from "~/types";
import { getLocalMessageId } from "~/utils";
import useScroll from "../useScroll";
import { getBotWelcomeMsgRenderTs, setBotWelcomeMsgRenderTs } from "~/utils/storage";

interface IProps {
  scrollDOMRef: React.RefObject<HTMLDivElement>;
}

export default function useHistory({ scrollDOMRef }: IProps) {
  const [fetchHistoryPending, setFetchHistoryPending] = useState(false);
  const [ifHasMoreMessage, setIfHasMoreMessage] = useState(false);

  const { getBotCode } = useBotStore();
  const botCode = getBotCode();

  const [page, setPage] = useState(1);
  const {
    info: { agentId, tenantId },
  } = useUserStore();
  const {
    customize: { defaultMessageSetting, recommendQuestions, serviceStatus },
  } = useCustomizeStore();

  const isServiceAvailable = serviceStatus === BotServiceStatus.ACTIVE;

  const { appendMessage, prependMessages } = useMessageStore();

  const { scrollToTop, scrollToBottom } = useScroll({ scrollContainerRef: scrollDOMRef });

  // 首次加载
  useRequest(() => getHistoryMessages(botCode, { page }), {
    onSuccess: (res) => {
      handleFormatResult(res);
      appendInitialMessage(res.messages.length > 0);
    },
    ready: isServiceAvailable,
  });

  // 获取历史消息
  const { run: fetchMoreMessages } = useRequest(
    () => {
      setPage((prev) => prev + 1);
      setFetchHistoryPending(true);
      return getHistoryMessages(botCode, { page: page + 1 });
    },
    {
      onSuccess: (res) => {
        handleFormatResult(res);
        scrollToTop();
      },
      onFinally: () => {
        setFetchHistoryPending(false);
      },
      manual: true,
      refreshDeps: [page],
      ready: isServiceAvailable,
    }
  );

  function handleFormatResult(result: HistoryMessagesResponse) {
    const { messages: data, pagination } = result;

    setIfHasMoreMessage(pagination.currentPage < pagination.totalPages);

    const messages = data
      .reverse()
      .map((item: any) => {
        const isBot = item.messageFrom === "Bot";
        let type = item.type;
        let text = item.content;
        let compareData = [] as ProductCompareItem[];
        if (type === MessageType.INSTRUCTION_COMPARE) {
          if (isBot) {
            // todo: 摘出来 compareData
            compareData = item.metadata?.resource?.InstructionCompare ?? [];
          } else {
            // 将 Comparison 的 User 的信息给转化一下
            type = MessageType.TEXT;
            text = "Comparison";
          }
        }

        if (
          type === MessageType.ONLY_CHAT ||
          type === MessageType.KNOWLEDGE_SEARCH ||
          type == null // 暂时为了兼容db里面没有类型的数据
        ) {
          type = MessageType.TEXT;
        }

        let feedback: FeedbackLog | undefined;
        if (item.feedback) {
          feedback =
            item.feedback === "like"
              ? FeedbackLog.UPVOTED
              : item.feedback === "dislike"
                ? FeedbackLog.DOWNVOTED
                : undefined;
        }

        const message: Message = {
          type,
          text,
          isBot,
          messageId: item.runId,
          createdAt: item.createdTime,
          feedback,
          isHistory: true,
        };

        if (type === MessageType.INSTRUCTION_COMPARE) {
          message.compareData = compareData;
        }

        if (type === MessageType.QUESTION_RECOMMENDR) {
          message.payload = item?.metadata?.questionRec;
        }

        if (item?.metadata) {
          message.resource = item.metadata?.resource;
        }
        return message;
      })
      .filter(Boolean) as Message[];

    // FIXME:这里优化一下，等渲染完之后再滚动到底部
    setTimeout(scrollToBottom);

    prependMessages(messages);
  }

  const appendWelcomeMessage = (hasHistoryConversation: boolean) => {
    const latestRenderTimestamp = getBotWelcomeMsgRenderTs(botCode);

    if (
      defaultMessageSetting.welcome &&
      (!hasHistoryConversation ||
        !latestRenderTimestamp ||
        JSON.parse(latestRenderTimestamp) <= Date.now() - 24 * 60 * 60 * 1000)
    ) {
      const welcomeMessage: Message = {
        type: MessageType.TEXT,
        subType: MessageSubType.WELCOME,
        text: defaultMessageSetting.welcome,
        isBot: true,
        messageId: getLocalMessageId(),
      };

      appendMessage(welcomeMessage);
      appendRecommendQuestions();

      setBotWelcomeMsgRenderTs(botCode, Date.now());
    }
  };

  const appendRecommendQuestions = () => {
    if (recommendQuestions.length) {
      const recommendQuestionsMessage: Message = {
        type: MessageType.QUESTION_RECOMMENDR,
        payload: recommendQuestions.map((content) => ({
          type: RecommendQuestionType.TEXT,
          content,
        })),
        isBot: true,
        messageId: getLocalMessageId(),
      };

      appendMessage(recommendQuestionsMessage);
    }
  };

  const appendInitialMessage = (hasHistoryConversation: boolean) => {
    const divideMessage: Message = {
      type: MessageType.DIVIDER,
      text: "Above is previous chat history",
      isBot: false,
      messageId: getLocalMessageId(),
    };

    appendMessage(divideMessage);
    appendWelcomeMessage(hasHistoryConversation);
    // shouldAppendIntroduceVideoMessage();
    setTimeout(scrollToBottom);
  };

  // function shouldAppendIntroduceVideoMessage() {
  //   let isWhiteListParentHost = false;
  //   if (isIframe()) {
  //     if (integrationConfig?.parentHref) {
  //       const whiteListParenthosts = [
  //         "locahost:5301",
  //         "locahost:5401",
  //         "development.targetpilot.ai",
  //         "admin.targetpilot.ai",
  //       ];
  //       const parentURLObject = new URL(integrationConfig.parentHref);
  //       const parentHost = parentURLObject.hostname;
  //       whiteListParenthosts.includes(parentHost);
  //       isWhiteListParentHost = true;
  //     }
  //   }

  //   // 在目标 hosts 页面中，以及开发环境中，渲染视频介绍
  //   if (isWhiteListParentHost) {
  //     const videoMessage: Message = {
  //       type: MessageType.VIDEO,
  //       mediaUrl: "https://www.youtube.com/watch?v=J8tfbmEf9oE&ab_channel=TargetPilot",
  //       isBot: true,
  //       messageId: getLocalMessageId(),
  //     };

  //     appendMessage(videoMessage);
  //   }
  // }

  return {
    fetchMoreMessages,
    fetchHistoryPending,
    ifHasMoreMessage,
  };
}
